<template>
  <highcharts class="chart-component--custom" :options="getChartData()"></highcharts>
</template>

<script>
// import Highcharts from 'highcharts'

export default {
  mounted () {
  },
  props: {
    schemaList: Array, // Define el tipo de datos esperado para schemaList (puede ser String, Number, Array, Object, etc.)
    nameChart: String
  },
  methods: {
    getChartData () {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: 'Eventos de sucursal ' + this.nameChart,
          align: 'left'
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
          point: {
            valueSuffix: '%'
          }
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
          }
        },
        series: this.schemaList
      }
    }
  }
}
</script>

<style lang="css">
</style>
