import request from '@/utils/request-biometric'
export function search (params) {
  return request({
    url: '/bio-event/search',
    method: 'post',
    params
  })
}

export function lineChartEventsSearch (data) {
  return request({
    url: '/biometric/events/lineChartService',
    method: 'post',
    data
  })
}

export function pieChartEventsSearch (data) {
  return request({
    url: '/biometric/events/pieChartService',
    method: 'post',
    data
  })
}

export function filterData (data) {
  return request({
    url: '/biometric/events/filterData',
    method: 'post',
    data
  })
}

export function getPage (page, noitems, params) {
  return request({
    url: '/bio-event/search?page=' + page + '&item=' + noitems,
    method: 'post',
    params
  })
}

export function exportExcel (data) {
  return request({
    url: 'bio-event/export',
    method: 'post',
    responseType: 'blob',
    data
  })
}
