<template>
  <highcharts class="chart-component--custom" :options="getChartData()"></highcharts>
</template>

<script>
import Highcharts from 'highcharts'

export default {
  mounted () {
  },
  props: {
    schemaList: Array // Define el tipo de datos esperado para schemaList (puede ser String, Number, Array, Object, etc.)
  },
  data () {
    return {
      titleVertical: 'Alumnos'
    }
  },
  methods: {
    getChartData () {
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'line'
        },
        title: {
          text: 'Registro de Eventos en Sucursales de Club Cañada',
          align: 'center'
        },
        yAxis: {
          title: {
            text: this.titleVertical
          },
          labels: {
            format: '{value}°'
          }
        },
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            month: '%e. %b',
            year: '%b'
          },
          title: {
            text: 'Fechas'
          }
        },
        legend: {
          layout: 'vertical',
          align: 'right',
          verticalAlign: 'middle'
        },
        plotOptions: {
          series: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              style: {
                color:
                  (Highcharts.theme && Highcharts.theme.contrastTextColor) ||
                  'black'
              }
            }
          }
        },
        series: this.schemaList
      }
    }
  }
}
</script>

<style lang="css">
</style>
