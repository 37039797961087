<template>
  <div class="dashboard-container">
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="8">
        <el-select v-model="searchFormLineChart.key" multiple clearable @change="updateDataLineChart">
          <el-option
            v-for="key in keysOptions"
            :key="key.key"
            :label="key.key"
            :value="key.key"
          ></el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="8">
        <el-select v-model="searchFormLineChart.bio_location_key" multiple clearable @change="updateDataLineChart">
          <el-option
            v-for="item in biolocationList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
         </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="12" :md="12" :sm="8" :xs="24">
        <div style="text-align: center;" class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRangeLineChart"
            v-on:change="handleFilterDateRegisterLineChart()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <div>
      <LineChartEvents :schemaList="schemaListLine" ></LineChartEvents>
    </div>
    <el-divider></el-divider>
    <el-row justify="center">
      <el-col style="display: flex; justify-content: center;" :lg="4" :md="4" :sm="8" :xs="8">
        <el-select v-model="searchFormPieChart.bio_location_key" clearable @change="updateDataPieChart">
          <el-option
            v-for="item in biolocationList"
            :key="item.value"
            :label="item.label"
            :value="item.value">
         </el-option>
        </el-select>
      </el-col>
      <el-col style="display: flex; justify-content: center;" :lg="12" :md="12" :sm="8" :xs="24">
        <div style="text-align: center;" class="grid-content bg-purple">
          <span class=" margin-filter">Registros por fecha</span>
          <el-date-picker
            v-model="filterDateRegisterRangePieChart"
            v-on:change="handleFilterDateRegisterPieChart()"
            size="mini"
            type="daterange"
            align="right"
            unlink-panels
            format="dd-MM-yyyy"
            range-separator="a"
            start-placeholder="Fecha inicial"
            end-placeholder="Fecha final"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      </el-col>
    </el-row>
    <div>
      <PieChartEvents :schemaList="schemaListPie" :nameChart="namePieChart"></PieChartEvents>
    </div>
    <!------
    <el-row justify="center">
      <el-col :span="20">
        <div class="graphic-container" id="graphd">
          <Highcharts></Highcharts>
        </div>
      </el-col>
    </el-row>
    ---->
  </div>
</template>

<script>
// import Highcharts from '@/components/Highcharts/Highcharts.vue'
import { mapGetters } from 'vuex'
import { getScopes } from '@/api/admin'
import { search as locationSearch } from '@/api/bioLocations.js'
import { lineChartEventsSearch, pieChartEventsSearch, filterData } from '@/api/bioEvents'
import LineChartEvents from '@/components/Highcharts/LineChartEvents.vue'
import PieChartEvents from '@/components/Highcharts/PieChartEvents.vue'
import moment from 'moment'

export default {
  components: {
    LineChartEvents,
    PieChartEvents
  },
  data () {
    return {
      items: [],
      allScopes: [],
      schemaListLine: [],
      schemaListPie: [],
      register: true,
      data: [],
      keysOptions: [],
      biolocationList: [
        {
          value: null,
          label: 'Todas las Sucursales'
        },
        {
          value: 'GRUTA',
          label: 'Gruta'
        }
      ],
      namePieChart: '',
      searchFormLineChart: {
        key: ['access'],
        bio_location_key: [],
        initDate: '',
        endDate: ''
      },
      searchFormPieChart: {
        bio_location_key: 'SAT',
        initDate: '',
        endDate: ''
      },
      pickerOptions: {
        shortcuts: [{
          text: 'Semana pasada',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Mes pasado',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '3 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '6 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 180)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '9 Meses atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 270)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '1 Año atrás',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      filterDateRegisterRangeLineChart: [moment().subtract(7, 'days').toDate(), moment().toDate()],
      filterDateRegisterRangePieChart: [moment().subtract(7, 'days').toDate(), moment().toDate()]
    }
  },
  created () {
    this.getAllScopes()
  },
  mounted () {
    this.getLocationSelectOptions()
    this.searchFormLineChart.initDate = moment(this.filterDateRegisterRangeLineChart[0])
    this.searchFormLineChart.endDate = moment(this.filterDateRegisterRangeLineChart[1])
    this.searchFormPieChart.initDate = moment(this.filterDateRegisterRangePieChart[0])
    this.searchFormPieChart.endDate = moment(this.filterDateRegisterRangePieChart[1])
    this.loadDataLine()
    this.loadDataPie()
    this.setKeyFilter()
  },
  computed: {
    ...mapGetters([
      'scopes'
    ])
  },
  methods: {
    setScopes () {
      this.allScopes.forEach((item) => {
        if (this.scopes.includes(item.value)) {
          this.items.push(item.label)
        }
      })
    },
    setKeyFilter () {
      filterData({ fieldSearch: 'key' })
        .then((response) => {
          this.keysOptions = response.data
        })
    },
    async getLocationSelectOptions () {
      try {
        const response = await locationSearch({ query: '' })
        if (response.success) {
          const locationData = await response.data.data
          locationData.forEach(location => {
            this.biolocationList.push({
              label: location.name,
              value: location.key
            })
          })
        }
      } catch (err) {
        console.log(err)
      }
    },
    getAllScopes () {
      getScopes()
        .then((response) => {
          this.allScopes = response.data
          this.setScopes()
        })
    },
    loadDataLine () {
      const formData = new FormData()
      formData.append('bio_location_key', Object.values(this.searchFormLineChart.bio_location_key))
      formData.append('key', Object.values(this.searchFormLineChart.key))
      formData.append('initDate', this.searchFormLineChart.initDate)
      formData.append('endDate', this.searchFormLineChart.endDate)
      lineChartEventsSearch(formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // Establece el encabezado correcto aquí
        }
      })
        .then((response) => {
          console.log(response)
          if (response.data) {
            // var data = Object.values(response.data)
            var nameSucursales = Object.keys(response.data)
            var seriesSucursales = Object.values(response.data)
            var i = 0
            // sucursales cada una con sus conjuntos de datos
            Object.values(seriesSucursales).forEach(seriesSucursal => {
              // conjuntos de datos por sucursal
              var j = 0
              Object.values(seriesSucursal).forEach(serieSucursal => {
                // conjunto de datos de una serie o grafica de una sucursal
                var legends = Object.keys(seriesSucursal)
                var serieLine = {
                  name: nameSucursales[i] + '-' + legends[j],
                  data: []
                }
                Object.keys(serieSucursal).forEach(fecha => {
                  if (fecha !== 'subtotal') {
                    serieLine.data.push({ x: moment(fecha, 'DD-MM-YYYY').toDate(), y: serieSucursal[fecha] })
                  }
                })
                if (legends[j] !== 'total') {
                  this.schemaListLine.push(serieLine)
                }
                j++
              })
              i++
            })
            // var tablasSucursales = [...Object.values(response.data)]
          }
        })
    },
    loadDataPie () {
      const formData = new FormData()
      formData.append('bio_location_key', this.searchFormPieChart.bio_location_key)
      formData.append('initDate', this.searchFormPieChart.initDate)
      formData.append('endDate', this.searchFormPieChart.endDate)
      pieChartEventsSearch(formData, {
        headers: {
          'Content-Type': 'multipart/form-data' // Establece el encabezado correcto aquí
        }
      })
        .then((response) => {
          const sucursales = response.data
          for (const sucursal in sucursales) {
            var currentSucursal = sucursales[sucursal]
            this.namePieChart = sucursal
            var seriePie = {
              name: sucursal,
              colorByPoint: true,
              data: []
            }
            for (const key in currentSucursal) {
              if (key !== 'total') {
                var percent = (currentSucursal[key].subtotal / currentSucursal.total) * 100
                seriePie.data.push({ name: key, y: percent })
              }
            }
            this.schemaListPie.push(seriePie)
            // objetoTransformado[clave] = objetoOriginal[clave];
          }
        })
    },
    updateDataLineChart () {
      this.schemaListLine = []
      this.loadDataLine()
    },
    updateDataPieChart () {
      this.schemaListPie = []
      this.namePieChart = ''
      this.loadDataPie()
    },
    handleFilterDateRegisterLineChart () {
      if (this.filterDateRegisterRangeLineChart) {
        this.searchFormLineChart.initDate = moment(this.filterDateRegisterRangeLineChart[0])
        this.searchFormLineChart.endDate = moment(this.filterDateRegisterRangeLineChart[1])
        this.updateDataLineChart()
      } else {
        this.searchFormLineChart.initDate = ''
        this.searchFormLineChart.endDate = ''
        this.updateDataLineChart()
      }
    },
    handleFilterDateRegisterPieChart () {
      if (this.filterDateRegisterRangePieChart) {
        this.searchFormPieChart.initDate = moment(this.filterDateRegisterRangePieChart[0])
        this.searchFormPieChart.endDate = moment(this.filterDateRegisterRangePieChart[1])
        this.updateDataPieChart()
      } else {
        this.searchFormPieChart.initDate = ''
        this.searchFormPieChart.endDate = ''
        this.updateDataPieChart()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard {
  &-container {
    margin: 30px;
  }
  &-text {
    font-size: 30px;
    line-height: 46px;
  }
}
.el-carousel__item h3 {
    color: #475669;
    font-size: 40px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
    text-align: center;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>
